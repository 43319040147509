/*****************************************
Landing Page Style starts
*****************************************/
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-x: hidden;
}

/* 18/11/2019 */
.fieldset {
  width: 100%;
}
.left_user_ico img {
  height: auto !important;
}

.btm-light {
  background: #eaeaea !important;
}

.imageBackground {
  background: url("../images/bg.jpg") no-repeat 50% 50% fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100%;
  /* height: 100%; */
}

.container-fluid {
  position: relative !important;
}
/* .main_box{width: 875px; margin: 30px auto; background: #fff;} */
.main_box {
  /* width: 875px;  */
  background: #fff;
}

.dialoge_left {
  background: #1774bb;
  text-align: center;
  padding: 30px 0px;
  color: #fff;
  font-weight: normal;
}
.dialoge_left h3 {
  font-size: 23px;
  padding: 20px 0px;
  color: #fff;
}
.dialoge_left h4 {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 30px;
  color: #fff;
}
.right_popup {
  padding: 30px 15px 80px 20px;
  position: relative;
  min-height: 496px;
}
.right_popup h2 {
  font-size: 23px;
  font-weight: 800;
  line-height: 50px;
  color: #333; /*border-bottom: 1px solid #666;*/
  margin-bottom: 15px;
  margin-top: 25px;
}
.right_popup p {
  font-size: 16px;
  text-align: center;
  color: #666;
}

/*.relation_box{}*/
.relation_box ul {
  margin: 0px;
  padding: 0px;
}

.relation_box ul li {
  padding: 8px 10px;
  list-style: none;
  display: inline-block;
  margin-right: 5px;
  border: 1px solid #f4f4f4;
  cursor: pointer;
  border-radius: 10px;
  background: #f4f4f4;
  margin-bottom: 8px;
}
.relation_box ul li:hover {
  background: #1774bb;
  border: 1px solid #1774bb;
  color: #fff;
}
.relation_box ul li.active input[type="radio"] {
  opacity: 0;
}

.relation_box ul li.active {
  background: rgba(66, 222, 177, 0.1);
  border: 3px solid #42deb1;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.otp {
  margin: 0;
  padding: 0;
  color: #b1b0b0;
  font-size: 13px;
  cursor: pointer;
}

.remember {
  color: #666;
  font-size: 14px;
}

.opt_out {
  padding: 5px 10px 0px 0px;
  margin-top: -5px;
}

/*old*/
.main_bg {
  background: #fff;
  width: 100%;
}
.left_area {
  padding: 20px 40px;
  margin-top: 200px;
}
/*.company_logo{}*/
.landing_page_txt {
  padding-top: 90px;
  padding-left: 60px;
}
.landing_page_txt h1 {
  color: #ffffff;
  font-size: 50px;
}
.landing_page_txt h2 {
  font-size: 30px;
  font-weight: bold;
  color: #edb90e;
  line-height: 24px;
  margin-top: 20px;
}
.landing_page_txt h2 span {
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
}
.landing_page_txt ul {
  margin: 35px 0px 0px 30px;
  padding: 0;
}
.landing_page_txt ul li {
  list-style: circle;
  color: #fff;
  line-height: 26px;
  font-size: 16px;
  font-weight: normal;
}
.footer_links {
  position: absolute;
  bottom: 0px;
}
.footer_links ul {
  margin: 0px 0px 10px;
  padding: 0;
}
.footer_links ul li {
  list-style: none;
  display: inline-block;
  color: #ffffff;
  font-size: 15px;
  padding: 0px 15px;
}
.footer_links ul li a {
  text-decoration: none;
  color: #fff;
}
.footer_links ul li a:hover {
  color: #d8d8d8;
}
.footer_links ul li:first-child {
  padding-left: 0px;
}
.footer_links p {
  font-size: 14px;
  line-height: 18px;
  color: #c4c4c4;
}
.footer_links_n {
  padding: 15px 10px;
}
.footer_links_n ul {
  margin: 0px 0px 10px 0px;
  padding: 0;
}
.footer_links_n ul li {
  list-style: none;
  display: inline-block;
  color: #333;
  font-size: 15px;
  padding: 0px 15px;
}
.footer_links_n ul li a {
  text-decoration: none;
  color: #333;
}
.footer_links_n ul li a:hover {
  text-decoration: none;
  color: #333;
}
.footer_links_n ul li:first-child {
  padding-left: 0px;
}
.footer_links_n p {
  font-size: 12px;
  line-height: 18px;
  color: #666;
}
.login_area {
  position: relative;
  right: -15px;
  background: #ffffff;
  /*height: 100vh;
	padding:70px 60px 120px 60px;*/
  height: 100%;
  padding: 20px 60px 10px 60px;
  bottom: 0px;
  top: 0px;
}
.login_area h2 {
  margin-bottom: 20px;
}
.login_area h6 {
  font-size: 15px;
  line-height: 20px;
  color: #666;
  font-weight: normal;
  margin-bottom: 30px;
}
.btns_in {
  text-align: center;
}
.pp {
  position: relative;
}
.pp img {
  position: absolute;
  right: 10px;
  top: 10px;
}
.login_btn {
  background: #4b89ff;
  padding: 15px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  margin-bottom: 8px;
  min-width: 100%;
  font-size: 15px;
}
.login_btn:hover {
  color: #fff;
  text-decoration: none;
  background: #457de6;
}
.forgetpwd {
  color: #229be6;
  text-decoration: none;
  font-size: 14px;
}
.forgetpwd:hover {
  text-decoration: underline;
  color: #229be6;
}
.or_section {
  border-bottom: 1px solid #707070;
  position: relative;
  text-align: center;
  margin: 30px 0px;
}
.or_section span {
  position: absolute;
  top: -15px;
  background: #ffffff;
  padding: 5px;
  color: #707070;
  font-size: 14px;
}
.google_login {
  border: 1px solid #F5F5F5;
  text-align: center;
  margin-top: 20px;
  position: relative;
  border-radius: 3px;
  color: #fff;
  padding: 15px 0px;
  cursor: pointer;
  background: #ef4237;
  font-size: 15px;
}
.google_login a {
  text-decoration: none;
  color: #fff;
  text-decoration: none;
}
.google_login:hover {
  border: 1px solid #4b89ff;
  text-decoration: none;
  color: #f1f1f1;
}
.google_login img {
  position: absolute;
  left: 15px;
  top: 10px;
}
.powered {
  position: relative;
  margin-top: 5px;
}
.powered_login img {
  max-width: 180px;
}
.powered_login {
  padding: 15px 0px 20px 0px;
  position: absolute;
  right: 20px;
  bottom: 0px;
}

/**************************************
2nd Page******************************/
.membersList {
  /* padding-top:10px !important;
	padding-bottom: 10px !important; */
  padding: 10px 25px !important;
  margin-right: 8px;
}
.form-control {
  border-bottom: 1px solid #d7d7d7 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  font-size: 14px !important;
  border-radius: 0px !important;
  padding: 0px !important;
  box-shadow: none !important;
}
.form-control:focus {
  box-shadow: none !important;
}
.dialoge_box {
  width: 800px;
  margin: 30px auto;
  background: #ffffff;
  border-radius: 2px;
}
.myform_new {
  float: left;
}
.relation_status {
  margin-top: 30px;
}
.relation_status h4 {
  font-size: 16px;
  color: #333;
}
.relation_status span {
  font-size: 16px;
  margin: 10px 0px 0px 10px;
}
.myform_new > input[type="text"] {
  border-left: none;
  border-top: none;
  border-right: none;
}
.inner_logo {
  padding: 10px;
  text-align: left;
}
.bg_graph {
  position: absolute;
  bottom: 0;
  left: 0;
}
.left_user_ico {
  position: relative;
}

.main_gender {
  float: left;
  margin-right: 15px;
  cursor: pointer;
}
.main_gender img {
  width: 65px;
  max-width: 65px;
}
.main_gender p {
  font-size: 13px;
  color: #999;
}

.status_box {
  float: left;
  text-align: center;
  margin-right: 25px;
}
.status_one {
  text-align: center;
  width: 75px;
  height: 75px;
  border-radius: 50px;
  border: 1px solid #999;
  padding-top: 10px;
  cursor: pointer;
}
.status_one:hover {
  border: 1px solid #4b89ff;
}

.status_one img {
  vertical-align: middle;
}

.male_img {
  background: url("../images/male.png") 0 0 no-repeat;
  background-size: 53px 66px;
  text-align: center;
  width: 53px;
  height: 66px;
  margin: auto;
}

.single_img {
  background: url("../images/single.png") 0 0 no-repeat;
  background-size: 53px 66px;
  text-align: center;
  width: 53px;
  height: 66px;
  margin: auto;
}

.status_one:hover .single_img {
  background: url("../images/single_fill.png") 0 0 no-repeat;
  background-size: 53px 66px;
  text-align: center;
  width: 53px;
  height: 66px;
}

.gender_box_n {
  position: relativew;
  width: 100%;
  padding-bottom: 5px;
}
.gender_box {
  border-bottom: 1px solid #d7d7d7;
  position: relativew;
  width: 100%;
  padding-bottom: 5px;
}
.add_new {
  position: absolute;
  top: 0px;
  width: 30px;
  height: 30px;
  background: #f1f1f1;
  right: -25px;
  color: #4b89ff;
  border-radius: 30px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  /* border:1px solid #4B89FF; */
  cursor: pointer;
}

.add_new:hover {
  background: #4b89ff;
  color: #fff;
  border: 1px solid #4b89ff;
}

.gender_section {
  width: 55px;
  float: left;
  margin-right: 10px;
  cursor: pointer;
}

.male_icon {
  background: url("../images/male_icon.png") 0 0 no-repeat;
  background-size: 30px 30px;
  text-align: center;
  width: 30px;
  height: 30px;
}

.others_icon {
  background: url("../images/others.png") 0 0 no-repeat;
  background-size: 30px 30px;
  text-align: center;
  width: 30px;
  height: 30px;
}
.female_icon {
  background: url("../images/female_icon.png") 0 0 no-repeat;
  background-size: 30px 30px;
  text-align: center;
  width: 30px;
  height: 30px;
}

.gender_section:hover .others_icon {
  background: url("../images/others_icon_fill.png") 0 0 no-repeat;
  background-size: 30px 30px;
  text-align: center;
  width: 30px;
  height: 30px;
}

.gender_section:hover .female_icon {
  background: url("../images/female_icon_fill.png") 0 0 no-repeat;
  background-size: 30px 30px;
  text-align: center;
  width: 30px;
  height: 30px;
}
.gender_section:hover .male_icon {
  background: url("../images/male_icon_fill.png") 0 0 no-repeat;
  background-size: 30px 30px;
  text-align: center;
  width: 30px;
  height: 30px;
}
.male_icon span,
.female_icon span,
.others_icon span {
  padding-left: 40px;
  padding-top: 10px;
}

.status_box span {
  font-size: 13px;
  color: #666;
}

.family_img {
  background: url("../images/family.png") 0 0 no-repeat;
  background-size: 66px 66px;
  text-align: center;
  width: 66px;
  height: 66px;
  margin: auto;
}

.status_one:hover .family_img {
  background: url("../images/family_fill.png") 0 0 no-repeat;
  background-size: 66px 66px;
  text-align: center;
  width: 66px;
  height: 66px;
}
.next_bottom_btn {
  margin-top: 20px;
  text-align: right;
  /*display: inline-flex;
    position: absolute;
    right:30px;
    bottom:30px;*/
}

.next_btn {
  padding: 10px 30px;
  font-size: 15px;
  color: #fff !important;
  background: #4b89ff;
  border-radius: 3px;
}

.next_btn:hover {
  text-decoration: none;
  color: #fff;
  background: #457ce5;
  cursor: pointer;
}

.back_btn {
  border: 1px solid #999;
  padding: 9px 20px;
  color: #548fff;
  font-weight: 700;
  font-size: 13px;
  border-radius: 2px;
  margin-right: 15px;
}
.back_btn:hover {
  text-decoration: none;
  color: #548fff;
  border: 1px solid #666;
  cursor: pointer;
}

.btn_yn {
  padding: 10px 25px;
  border-radius: 2px;
  border: 1px solid #999;
  font-size: 13px;
  color: #666;
}

.btn_yn:hover {
  padding: 10px 25px;
  border-radius: 2px;
  border: 2px solid #4b89ff;
  font-size: 13px;
  color: #4b89ff;
  text-decoration: none;
}

.date_pick {
  background: url("../images/date_pick.png") 0 0 no-repeat;
  background-position: right;
}

/*income slab*/

.income_box {
  height: 90px;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
  background: #fdfdfd;
  /*float: left;*/
  margin: 7px;
  padding: 0px 10px;
  cursor: pointer;
  color: #333;
  line-height: 12px;
  text-align: center;
}

.income_box:hover {
  background: #4b89ff;
  color: #fff;
  /*float: left;*/
  margin-right: 5px;
  padding: 0px 10px;
  border: none;
}

.income_txt {
  text-align: center;
  line-height: 16px;
  font-size: 16px;
  /*left: 3px;*/
  position: relative;
  top: 21px;
}

.income_txt span {
  font-size: 24px;
  color: #d6d6d6;
}

.income_slab {
  margin: auto;
  width: 30%;
  float: left;
}

/**************************************
*******TopUp Plans********************/
.progress_bar {
  margin: auto;
  padding: 15px 10px 5px 10px;
  background: #bed1d9;
}
.topup_tag {
  background: #7a160a;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  padding: 10px 5px;
  color: #fff;
  margin-bottom: 20px;
}

.section_head {
  text-align: center;
  background: #fff;
}
.section_head h3 {
  font-size: 24px;
  margin: 0px;
  padding: 24px 10px 15px 10px;
  font-weight: 500;
  text-align: center;
}

.plan_right {
  background: #ffffff;
  overflow: scroll;
  position: relative;
  margin-top: 0px;
}

.progress_bar_new {
  width: 600px;
  margin: auto;
  padding: 10px;
  margin-top: 20px;
}

.progress_bar_new span {
  font-size: 24px;
  font-weight: bold;
  margin-right: 5px;
}

.pro_one,
.pro_two,
.pro_three {
  float: left;
  font-size: 16px;
  color: #fff;
  width: 180px;
  text-align: left;
  margin: 10px 5px 0px 5px;
}

.main_left_topup {
  background: #fff;
  padding: 20px;
}

.test {
  text-align: center;
  margin-left: 36px;
}

.section_head ul {
  margin: 0px;
  padding: 0;
}
.section_head ul li {
  display: inline-block;
  border: 1px solid #999;
  padding: 4px 10px;
  cursor: pointer;
  font-size: 13px;
}

.section_head ul li:hover {
  border: 1px solid #4b89ff;
  color: #4b89ff;
}

.relation_select {
  text-align: center;
  padding-top: 10px;
}

.know_more_link {
  font-size: 13px;
  color: #666;
  text-decoration: underline;
}
.know_more_link:hover {
  text-decoration: underline;
  color: #333;
}

.empty_cart {
  text-align: center;
  padding: 60px 15px 103px 15px;
}
.empty_cart p {
  font-size: 20px;
  color: #666;
  font-weight: bold;
  line-height: 24px;
}

.empty_cart h5 {
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  color: #999;
}

.empty_cart h6 {
  font-size: 13px;
  color: #999;
  font-weight: normal;
  line-height: 14px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.riskscore_box {
  margin-top: 10px;
  margin-right: 10px;
}

.risk_txt {
  border: 2px solid #f2f2f2;
  float: left;
  padding: 10px;
  line-height: 18px;
  font-size: 13px;
}

.risk_txt_single {
  border: 2px solid #f2f2f2;
  float: left;
  padding: 20px 15px;
  line-height: 18px;
  font-size: 13px;
}
.risk_score {
  float: left;
  text-align: center;
  background: #f2f2f2;
  padding: 10px;
}
.risk_score h5 {
  font-size: 24px;
  line-height: 14px;
  font-weight: bold;
  color: #00174c;
}

.risk_score h5 span {
  font-size: 11px;
  font-weight: normal;
  color: #666;
}
.button_div {
  float: right;
  margin: 10px 0px;
}
.button_div a {
  margin-left: 15px;
  padding: 5px 25px;
}
.note {
  float: left;
}
.note h6 {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  margin: 0;
  padding: 0;
}

.note p {
  font-size: 12px;
  color: #666;
  padding: 0;
  margin: 0;
}
.footer_bg {
  background: #fff;
  padding: 15px 10px 0px;
  position: absolute;
  left: 0;
  right: 0;
}

/*forgot password*/
.forgot {
  width: 50%;
  margin: auto;
  text-align: center;
}
.forgot input {
  text-align: center;
}
.forgot_password_main {
  max-width: 650px;
  margin: 40px auto;
  background: #fff;
  padding: 30px;
  text-align: center;
  min-height: 400px;
  position: relative;
}

.forgot_password_main h2 {
  font-size: 24px;
}
.forgot_password_main h3 {
  font-size: 18px;
  font-weight: normal;
  margin: 20px;
}

.submit_btn {
  padding: 10px 50px;
  max-width: 100%;
  background: #4b89ff;
  margin-top: 20px;
  color: #fff;
  border: none;
  border-radius: 3px;
}
.ideal_cover {
  text-align: center;
}
.ideal_cover i {
  font-size: 17px;
  background: red;
  color: #fff;
  margin-right: 10px;
  padding: 3px 14px;
  border-radius: 19px;
}

/*Right side cart start here*/
.policy_cart {
  border-top: 5px solid #f43762;
}
.total_section {
  padding: 10px;
}
.total_section h2 {
  font-size: 16px;
  text-align: left;
}
.total_section h2 span {
  float: right;
}
.total_section h5 {
  font-size: 13px;
  color: #666;
}
.total_section h5 span {
  float: right;
}
.total_section p {
  font-size: 13px;
  line-height: 12px;
  border-bottom: 1px dashed #d2d2d2;
  padding: 7px 0px;
  margin: 0px;
}
.total_section p:first-child {
  border-bottom: 0px solid #333;
}

.total_section p span {
  float: right;
}

.total_section h4 {
  font-size: 14px;
  padding: 10px 0px;
  border-top: 1px solid #666;
}
.total_section h4 span {
  float: right;
}
.premium_deduction {
  padding: 10px;
}
.premium_deduction h3 {
  font-size: 14px;
  color: #f08e50;
  font-weight: normal;
}

.selection_one {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 10px;
}

.radio_section_cart {
  margin-bottom: 1px;
  background: #c9ecf4;
  padding: 2px 10px;
  font-size: 12px;
}

.radio_section_cart span {
  float: right;
}

.premium_cart_head {
  background-color: antiquewhite;
  color: white;
  padding: 2px 0 10px 0;
  margin: 0;
}

.premium_cart_head span {
  background-color: #bf9000;
  font-size: 12px;
  line-height: 20px;
  padding: 5px 10px 5px 10px;
  margin-left: 0px;
}

.premium_cart {
  padding: 1px 10px 0px 10px;
  min-height: auto;
  max-height: 540px;
  overflow: scroll;
}
.premium_cart i {
  background: #dfba44;
  font-size: 13px;
  padding: 3px 5px 3px 10px;
  margin-left: -12px;
  color: #fff;
  font-style: normal;
}

.premium_cart a {
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  margin-left: 30px;
}
.premium_cart p {
  margin: 0;
  font-size: 13px;
  border-bottom: 1px dashed #d2d2d2;
  color: #666;
  padding: 5px 0px;
}
.premium_cart p span {
  float: right;
  color: #666;
  background: none;
}

.premium_cart h3 {
  font-size: 14px;
  padding: 5px 0px;
}
.premium_cart h3 span {
  font-size: 14px;
  float: right;
}

.save_now {
  position: absolute;
  background: #4b89ff;
  color: #fff !important;
  text-decoration: none;
  padding: 15px;
  font-size: 15px;
  left: 0;
  right: 0;
  bottom: 0;
}
.save_now span {
  float: right;
}

.save_now:hover {
  text-decoration: none;
  color: #fff;
}

.single_txt {
  font-style: normal;
  padding-top: 10px;
}

/*Progress bar starts*/
.progressbar {
  margin: 0;
  padding: 0;
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 10%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
  position: relative;
  z-index: 2;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: 0;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: green;
}
.progressbar li.active:before {
  border-color: #95c672;
  background: #95c672;
  color: #fff;
}
.progressbar li.active + li:after {
  background-color: #55b776;
}

/*Progress Bar ends here*/

/*Congratulations_tile*/
.Congratulations_tile {
  width: 550px;
  background: #fff;
  margin: 30px auto;
  border-top: 5px solid #f43762;
  padding-bottom: 30px;
  max-width: 100%;
}

.Congratulations_icon {
  text-align: center;
  margin: 20px 0px 5px 0px;
}
.Congratulations_icon img {
  max-width: 60px;
  margin-bottom: 10px;
}
.Congratulations_icon p {
  font-size: 30px;
  color: #4b89ff;
  line-height: 24px;
}

.Congratulations_icon p span {
  font-size: 16px;
  color: #666;
}

.policy_tile {
  width: 500px;
  margin: 15px auto;
  border: 1px solid #e8bc76;
  max-width: 98%;
}
.policy_tile h3 {
  font-size: 16px;
  background: #e8bc76;
  text-align: center;
  padding: 4px 10px;
  color: #fff;
}

.policy_tile h4 {
  text-align: right;
  font-size: 14px;
  color: #c49337;
  font-weight: normal;
  border-bottom: 1px solid #c49337;
  margin-bottom: 10px;
  padding-right: 20px;
  padding-bottom: 5px;
}
.policy_tile h4 span {
  margin-left: 40px;
}

.member_list {
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 0px 3px 0px;
  margin: 2px 10px;
}

.member_list:last-child {
  border-bottom: none;
}

.member_list p {
  line-height: 12px;
  margin: 0;
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.member_list p span {
  float: right;
  margin-left: 31px;
}

.member_list i {
  text-decoration: none;
  font-style: normal;
  padding-left: 10px;
  font-size: 13px;
  color: #999;
}

.member_list h6 {
  line-height: 12px;
  margin: 0;
  color: #666;
  font-size: 14px;
  font-weight: normal;
}

.member_list h6 span {
  float: right;
  margin-left: 31px;
  text-align: left;
  min-width: 80px;
}
.total_q {
  padding: 10px 0px;
}

.goto_bd_btn {
  text-align: center;
  padding: 10px;
}
.btn_dashboard {
  padding: 15px 20px;
  background: #4b89ff;
  border-radius: 2px;
  color: #fff;
}

.btn_dashboard:hover {
  color: #fff;
  text-decoration: none;
  background: #407bed;
}

/*switch style*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.annual-block {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.mt-20 {
  margin-top: 15%;
}
input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.member_list ul {
  margin: 0;
  padding: 0;
}
.member_list ul li {
  list-style: none;
  display: inline-block;
  font-size: 13px;
  color: #666;
  min-width: 90px;
}

.member_list h5 {
  font-size: 11px;
  color: #999;
  margin-left: 10px;
}

.progress {
  height: 10px !important;
  border-radius: 3px 3px !important;
}

.progress-bar {
  background-color: #67c723 !important;
}

@media (max-width: 576px) and (min-width: 320px) {
  .company_logo {
    text-align: center;
  }
  .company_logo img {
    max-width: 50px;
  }
  .left_area {
    padding: 20px 0px;
  }
  .landing_page_txt {
    padding: 30px 30px 5px 30px;
  }
  .landing_page_txt h1 {
    font-size: 36px;
  }
  .landing_page_txt h2 {
    font-size: 22px;
  }
  .landing_page_txt h2 span {
    font-size: 11px;
    line-height: 15px;
  }
  .landing_page_txt ul {
    margin: 20px 10px;
  }
  .landing_page_txt ul li {
    font-size: 13px;
    line-height: 18px;
  }
  .login_area {
    position: absolute;
    right: 15px;
    left: 15px;
    bottom: 20px;
    top: -30px;
    padding: 36px 15px 20px 15px;
    max-height: 360px;
  }
  .login_area h2 {
    font-size: 24px;
  }
  .login_btn {
    padding: 15px 138px;
  }
  .form-group.mb0 {
    margin-bottom: 0;
  }
  /*first Page*/
  .main_box {
    width: 97%;
    margin: 30px auto;
    margin-left: 3%;
    background: #fff;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .right_popup h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .dialoge_left h3 {
    padding: 3px 0px;
    font-size: 20px;
    margin-bottom: 2px;
  }
  .left_user_ico {
    padding: 5px;
  }
  .left_user_ico img {
    width: 70px;
    margin-bottom: 5px;
  }
  .dialoge_left h4 {
    padding-bottom: 5px;
  }
  .display {
    display: none;
  }
  .next_bottom_btn {
    position: relative;
    float: right;
    margin: 0 0px 0;
    right: 0px;
    bottom: 0px;
  }
  .status_one {
    width: 70px;
    height: 70px;
    padding-top: 5px;
  }
  .single_img {
    background-size: 46px 54px;
    width: 47px;
  }
  .family_img {
    background-size: 52px 52px;
    width: 56px;
    height: 66px;
  }
  .right_popup {
    padding: 10px 15px 5px 15px;
    height: auto;
    min-height: 10px;
  }
  .footer_links {
    position: absolute;
    bottom: -665px;
  }
  .powered img {
    width: 115px;
  }
  .footer_links_n {
    padding: 2px 0px;
  }
  .footer_links_n ul li {
    padding: 0px 2px;
    font-size: 13px;
  }
  .footer_links ul li {
    font-size: 13px;
    padding: 3px 5px;
  }
  .footer_links p {
    font-size: 12px;
  }
  .tr {
    margin-bottom: 15px;
  }
  .footer_links_n p {
    margin: 0px;
  }
  .powered {
    border-top: 1px solid #f2f2f2;
    position: relative;
    left: 0;
    right: 0;
  }
  .powered_login {
    padding: 15px 0px 20px 0px;
    border-top: 1px solid #f2f2f2;
    position: absolute;
    right: 20px;
  }

  .income_slab {
    width: 50%;
  }
  .plan_right {
    max-height: 300px !important;
  }

  /*Topup Plans*/
  .ideal_cover {
    padding-bottom: 10px;
  }
  .ideal_cover i {
    font-size: 13px;
  }
  .plan_right {
    margin-top: 15px;
    min-height: auto;
    margin-bottom: 20px;
    overflow: scroll;
  }
  .section_head h3 {
    font-size: 20px;
  }
  .empty_cart {
    padding: 30px 15px;
  }
  .premium_cart {
    max-height: 300px;
    overflow: scroll;
  }

  /*forgot password*/
  .forgot {
    width: 80%;
  }
  .forgot input {
    text-align: center;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
}

@media (min-width: 577px) and (max-width: 767px) {
  .landing_page_txt {
    padding: 30px 30px 5px 30px;
  }
  .landing_page_txt h1 {
    font-size: 36px;
  }
  .landing_page_txt h2 {
    font-size: 22px;
  }
  .landing_page_txt h2 span {
    font-size: 11px;
    line-height: 15px;
  }
  .landing_page_txt ul {
    margin: 20px 10px;
  }
  .landing_page_txt ul li {
    font-size: 13px;
    line-height: 18px;
  }
  .login_area {
    position: absolute;
    right: 75px;
    left: 75px;
    bottom: 20px;
    top: -30px;
    padding: 36px;
    max-height: 500px;
    height: 500px;
  }
  .login_area h2 {
    font-size: 24px;
  }
  .login_btn {
    padding: 15px 138px;
  }
  .footer_links {
    position: absolute;
    bottom: -665px;
  }

  /*inner pages*/

  .right_popup h2 {
    line-height: 24px;
  }
  .dialoge_left {
    padding: 15px 20px;
  }
  .display {
    display: none;
  }
  .right_popup {
    padding: 15px 25px 20px 25px;
  }
  .tr {
    margin-bottom: 15px;
  }

  /*footer style*/
  .powered img {
    width: 115px;
  }
  .footer_links ul li {
    font-size: 13px;
    padding: 3px 5px;
  }
  .footer_links p {
    font-size: 12px;
  }
  .plan_right {
    margin-top: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .main_box {
    width: 100%;
    margin: auto;
    background: #fff;
    margin-top: 60px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .main_box {
    width: 100%;
  }
  .plan_right {
    margin-left: -30px;
    width: 114%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .main_box {
    width: 100%;
    margin: auto;
    background: #fff;
    margin-top: 60px;
  }
  .plan_right {
    margin-left: -30px;
  }
  .landing_page_txt {
    padding: 30px 30px 5px 30px;
  }
  .landing_page_txt h1 {
    font-size: 36px;
  }
  .landing_page_txt h2 {
    font-size: 22px;
  }
  .landing_page_txt h2 span {
    font-size: 11px;
    line-height: 15px;
  }
  .landing_page_txt ul {
    margin: 20px 10px;
  }
  .landing_page_txt ul li {
    font-size: 13px;
    line-height: 18px;
  }
  .login_area {
    position: absolute;
    right: 75px;
    left: 75px;
    bottom: 20px;
    top: -30px;
    padding: 36px 15px 20px 15px;
    max-height: 500px;
    height: 500px;
  }
  .login_area h2 {
    font-size: 24px;
  }
  .login_btn {
    padding: 15px 138px;
  }
  .footer_links {
    position: absolute;
    bottom: -665px;
  }

  .powered img {
    width: 115px;
  }
  .footer_links ul li {
    font-size: 13px;
    padding: 3px 5px;
  }
  .footer_links p {
    font-size: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1600px) {
  .landing_page_txt {
    padding: 40px 10px 20px 20px;
  }
  .landing_page_txt h1 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .next_btn.saveNext {
    padding: 10px 0 !important;
    width: 100% !important;
    display: block;
    /*margin-left:5px;
	margin-right:3px;*/
  }
  .Congratulations_icon {
    margin: 10px 0px 0 0px;
  }
  .Congratulations_icon img {
    max-width: 40px;
  }
  .Congratulations_icon p {
    margin-bottom: 0;
    font-size: 16px !important;
  }

  .policy_tile {
    margin: 8px auto;
  }
  .voluntary_covers tr td {
    padding: 0.55rem !important;
  }
  .edit-btn {
    padding: 10px 30px !important;
    font-size: 15px !important;
  }
  .next_btn {
    float: right;
  }
  .next_btn.confirm-btn {
    float: none;
  }
  .back_button {
    width: 45%;
    float: left;
    margin-left: 0;
    font-weight: 700;
  }
  .back_btn {
    width: 45% !important;
  }
  .member_list h6 {
    font-size: 12px;
  }
  .member_list {
    padding: 4px 1px !important;
  }
  .next_bottom_btn {
    display: block;
  }

  .prev {
    float: right;
    margin-bottom: 10px;
    margin-right: 0;
  }
  .main_box .right_popup {
    padding-bottom: 0 !important;
  }
  .main_box {
    padding-bottom: 70px !important;
  }

  .pro_imgs,
  .chooseTxtHide {
    display: none;
  }
  .left_user_ico img {
    display: none;
  }
  .annual-block {
    display: block;
    font-size: 18px;
    background-color: #fff;
    padding: 8px 0;
  }
  .premium_box {
    display: none;
  }
  /*ST step1*/
  .relation_box ul li {
    margin-bottom: 0;
  }
  .relation_status {
    margin-top: 0;
  }
  .left_user_ico {
    padding: 10px !important;
  }
  .dialoge_left {
    padding: 0;
  }
  .right_popup h2 {
    margin-bottom: 0;
    margin-top: 0;
  }
  .mt-20 {
    margin-top: 5px;
  }
  /*EN step1*/
  /*ST step3*/
  .income_txt {
    top: 18px;
  }
  .income_box {
    height: 84px;
  }
  .income_txt span {
    font-size: 20px;
  }
  .box-bor {
    padding-bottom: 15px !important;
    margin-bottom: 15px !important;
  }
  .powered {
    padding: 0;
  }
  .right_popup {
    margin-left: -5px !important;
  }
  .membersList {
    margin: 4px 10px;
  }
  .right_popup p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .topupPolicyHead h5 {
    margin-bottom: 0;
  }
  /*EN step3*/
}
